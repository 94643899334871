(function ($) {
    let t;

    whatInput.specificKeys([9]);

    window.addEventListener('resize', function () {
        setHeaderSize();
        clearTimeout(t);
        t = setTimeout(onResize, 200);
    });
    window.addEventListener('scroll', setHeaderSize);
    window.addEventListener('touchmove', setHeaderSize);
    if (Modernizr.mq('(min-width: 64em)')) {
        dropMenu();
        closeMobileMenu();
    } else {
        closeDropMenu();
        mobileMenu();
        mobileSidebarNav();
    }
    onResize();
    initBody();
    searchText();

    function onResize() {
        if (Modernizr.mq('(min-width: 64em)')) {
            dropMenu();
            closeMobileMenu();
            closeMobileSidebarNav();
        } else {
            closeDropMenu();
            mobileMenu();
            mobileSidebarNav();
        }
    }

    function setHeaderSize() {
        const body = document.body;
        if (window.innerWidth < 800 || window.scrollY > 0) {
            body.classList.add('fixed-header');
        } else {
            body.classList.remove('fixed-header');
        }
    }

    $('a[href^="#"]').not('[taxindex=-1], [disabled], :hidden').on('click', function (e) {
        e.preventDefault();
        var $href = $(this).attr('href');
        var $offset = $(document).scrollTop() > 42 ? 170 : 270;
        window.scrollTo(0, Math.max($($href).offset().top - $offset, 45));
    });

    function searchText() {
        var $homeSearch = $('.search-homepage-form');
        if ($homeSearch.length < 1) {
            return;
        }
        var $searchInput = $('.search-input', $homeSearch);
        $searchInput.wrap('<div class="has-clear-icon"></div>').after(
            $(
                '<button type="button" class="button with-icon icon-only clear-text"><span class="visuallyhidden">Clear text</span></button>'
            ).on('click', function () {
                $(this).prev('input').val('').trigger('change').focus();
            })
        );

        function hideClear() {
            var $val = $searchInput.val();
            var $button = $searchInput.siblings('.clear-text');
            if ($val.length === 0 || $val === null) {
                $button.hide();
            } else {
                $button.show();
            }
        }

        hideClear();
        $searchInput.on('change textInput input', function () {
            hideClear();
        });
    }

    function initBody() {
        var $body = $('body');
        $body.addClass('loaded');
        setHeaderSize();
    }

    function dropMenu() {
        var $dropTrigger = $('.drop-down-trigger');
        var $animationDuration = 500;

        $dropTrigger.on('click', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            var $thisTrigger = $(this);
            var $thisMenu = $thisTrigger.siblings('.drop-down');
            var $otherMenu = $thisTrigger.parent().siblings().children('.drop-down');
            var $otherTrigger = $otherMenu.siblings('.drop-down-trigger');

            if ($thisMenu.is(':hidden')) {
                $otherMenu.removeClass('closing').removeClass('opening').hide();
                $thisTrigger
                    .attr({'aria-expanded': 'true'})
                    .addClass('opening')
                    .removeClass('closing');
                $otherTrigger
                    .attr({'aria-expanded': 'false'})
                    .addClass('closing')
                    .removeClass('opening');
                $thisMenu.show().addClass('opening').removeClass('closing');
            } else {
                $thisTrigger
                    .attr({'aria-expanded': 'false'})
                    .addClass('closing')
                    .removeClass('opening');
                $thisMenu.addClass('closing').removeClass('opening');
                setTimeout(function () {
                    $thisMenu.hide();
                }, $animationDuration);
            }
        });

        $(document).on('keydown', function (e) {
            if (e.keyCode === 27) {
                var $active = document.activeElement;
                var $ancestor = $($active).closest('.drop-down');
                if ($ancestor.length > 0) {
                    if ($ancestor.hasClass('opening')) {
                        $ancestor.addClass('closing').removeClass('opening');
                        setTimeout(function () {
                            $ancestor.hide();
                        }, $animationDuration);
                    }
                    $ancestor.siblings('a').focus().attr({'aria-expanded': 'false'});
                }
            }
        });
    }

    function closeDropMenu() {
        var $dropTrigger = $('.drop-down-trigger');
        var $dropMenus = $('.drop-down');
        var $subnavTrigger = $dropMenus.find('.has-children > a');
        $dropTrigger.each(function () {
            var $trigger = $(this);
            $trigger
                .attr({'aria-expanded': 'false'})
                .removeClass('closing')
                .removeClass('opening');
        });
        $dropMenus.each(function () {
            var $menu = $(this);
            $menu.removeClass('closing').removeClass('opening').hide();
        });
    }

    function mobileMenu() {
        var $mobileMenu = $('#mob-menu');
        var $openTrigger = $('#mob-menu-open-trigger');
        var $closeTrigger = $('.close-button', $mobileMenu);
        var $subnav = $('.subnav.level-2', $mobileMenu);
        // var $subsubnav = $('.subnav.level-3', $subnav);
        var $animationDuration = 500;
        var $nonNav = $(
            'main, footer, header > .show-desktop, header > .show-mobile > .header-top > .container > div:not(.menu-block),  header > .show-mobile > .header-top > .container > .menu-block > .button, header > .show-mobile > .header-bottom'
        );
        var $keyboardFocusableElements = $(
            'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])',
            $nonNav
        );
        $openTrigger.on('click', function () {
            $(this).attr({'aria-expanded': 'true'});
            setTimeout(function () {
                if (!$('body').hasClass('mobile-menu-active')) {
                    $('body').addClass('mobile-menu-active');
                    $mobileMenu.before('<div class="overlay"/>');
                }
            }, $animationDuration);
            $mobileMenu.show().addClass('opening').removeClass('closing');
            $nonNav.attr({'aria-hidden': true});
            $keyboardFocusableElements.attr({tabindex: -1});
        });
        $closeTrigger.on('click', function () {
            if ($mobileMenu.hasClass('opening')) {
                $mobileMenu.removeClass('opening').addClass('closing');
                setTimeout(function () {
                    $mobileMenu.hide();
                    $openTrigger.focus();
                }, $animationDuration);
            }
            if ($subnav.hasClass('opening')) {
                $subnav.removeClass('opening').addClass('closing');
                setTimeout(function () {
                    $subnav.hide();
                    $openTrigger.focus();
                }, $animationDuration);
            }
            $openTrigger.attr({'aria-expanded': 'false'});
            if ($('body').hasClass('mobile-menu-active')) {
                $('body').removeClass('mobile-menu-active');
                $mobileMenu.siblings('.overlay').remove();
            }
            $nonNav.removeAttr('aria-hidden');
            $keyboardFocusableElements.removeAttr('tabindex');
        });
        $(document).on('keydown', function (e) {
            if (e.keyCode === 27) {
                // ESC
                if ($mobileMenu.hasClass('opening')) {
                    $mobileMenu.removeClass('opening').addClass('closing');
                    setTimeout(function () {
                        $mobileMenu.hide();
                        $openTrigger.focus();
                    }, $animationDuration);
                }
                if ($subnav.hasClass('opening')) {
                    $subnav.removeClass('opening').addClass('closing');
                    setTimeout(function () {
                        $subnav.hide();
                        $openTrigger.focus();
                    }, $animationDuration);
                }
                $openTrigger.attr({'aria-expanded': 'false'});
                if ($('body').hasClass('mobile-menu-active')) {
                    $('body').removeClass('mobile-menu-active');
                    $mobileMenu.siblings('.overlay').remove();
                }
                $nonNav.removeAttr('aria-hidden');
                $keyboardFocusableElements.removeAttr('tabindex');
            }
        });
        var $navlistL1 = $('.nav-list.level-1', $mobileMenu);
        var $subnavL1Close = $navlistL1.find('.back-button.level-2');
        var $items = $navlistL1.children('li.has-children');
        $items.each(function () {
            var $item = $(this);
            $item.children('a').on('click', function (e) {
                e.preventDefault();
                $item
                    .children('.subnav.level-2')
                    .show()
                    .removeClass('closing')
                    .addClass('opening');
                $(this).attr({'aria-expanded': 'true'});
            });
        });
        $subnavL1Close.on('click', function () {
            var $thisSubnav = $(this).parents('.subnav.level-2');
            var $thisTrigger = $thisSubnav.siblings('a');
            $thisSubnav.removeClass('opening').addClass('closing');
            setTimeout(function () {
                $thisSubnav.hide();
            }, $animationDuration);
            $thisTrigger.attr({'aria-expanded': 'false'}).trigger('focus');
        });

        var $navlistL2 = $('.nav-list.level-2', $mobileMenu);
        var $subnavL2Close = $navlistL2.find('.back-button.level-3');
        var $items = $navlistL2.children('li.has-children');
        $items.each(function () {
            var $item = $(this);
            $item.children('a').on('click', function (e) {
                e.preventDefault();
                $item
                    .children('.subnav.level-3')
                    .show()
                    .removeClass('closing')
                    .addClass('opening');
                $(this).attr({'aria-expanded': 'true'});
            });
        });
        $subnavL2Close.on('click', function () {
            var $thisSubnav = $(this).parents('.subnav.level-3');
            var $thisTrigger = $thisSubnav.siblings('a');
            $thisSubnav.removeClass('opening').addClass('closing');
            setTimeout(function () {
                $thisSubnav.hide();
            }, $animationDuration);
            $thisTrigger.attr({'aria-expanded': 'false'}).trigger('focus');
        });
    }

    function closeMobileMenu() {
        var $mobileMenu = $('#mob-menu');
        var $triggers = $('[aria-expanded]');
        var $subnav = $('.subnav.level-2', $mobileMenu);
        var $subsubnav = $('.subnav.level-3', $subnav);
        var $nonNav = $(
            'main, footer, header > .show-desktop, header > .show-mobile > .container > div:not(.menu-block),  header > .show-mobile > .container > .menu-block > .button'
        );
        var $keyboardFocusableElements = $(
            'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])',
            $nonNav
        );
        $mobileMenu.removeClass('opening').removeClass('closing').hide();
        $subnav.removeClass('opening').removeClass('closing').hide();
        $subsubnav.removeClass('opening').removeClass('closing').hide();
        $triggers.attr({'aria-expanded': 'false'});
        if ($('body').hasClass('mobile-menu-active')) {
            $('body').removeClass('mobile-menu-active');
            $mobileMenu.siblings('.overlay').remove();
        }
        $nonNav.removeAttr('aria-hidden');
        $keyboardFocusableElements.removeAttr('tabindex');
    }

    function mobileSidebarNav() {
        var $sidebarNav = $('#sidebarNav-mob');
        if ($sidebarNav.length < 1) {
            return;
        }
        var $trigger = $('#sidebar-toggle');
        var $nav = $('.sidebar-nav', $sidebarNav);
        var $animationDuration = 500;
        $trigger.on('click', function (e) {
            e.stopImmediatePropagation();
            if ($nav.is(':hidden')) {
                $trigger.attr({'aria-expanded': 'true'});
                $nav.show().addClass('opening').removeClass('closing');
            } else {
                $trigger.attr({'aria-expanded': 'false'});
                $nav.addClass('closing').removeClass('opening');
                setTimeout(function () {
                    $nav.hide();
                }, $animationDuration);
            }
        });
    }

    function closeMobileSidebarNav() {
        var $sidebarNav = $('#sidebarNav-mob');
        if ($sidebarNav.length < 1) {
            return;
        }
        var $trigger = $('#sidebar-toggle');
        var $nav = $('.sidebar-nav', $sidebarNav);
        $nav.removeClass('opening').removeClass('closing').hide();
        $trigger.off('click').attr({'aria-expanded': 'false'});
    }
})(jQuery);

const adBlockImages = document.querySelectorAll('.ad-block-image');
adBlockImages.forEach(adBlockImage => {
    const image = new Image();
    image.src = getComputedStyle(adBlockImage).getPropertyValue('--ad-desktop-img').slice(4, -1).replace(/"/g, '');
    image.onload = function () {
        const width = this.width;
        const height = this.height;

        if (width < 1000 || height < 0.3 * window.innerHeight) {
            adBlockImage.classList.add('small-image');
        }
    };
});
